import Swiper from 'swiper';
import 'swiper/css/bundle';

import "@styles/pages/home.scss";

import "@scripts/pinia.ts";
import "@scripts/app.ts";
import '@scripts/enquiry-form.ts';
import '@scripts/offer-hero.js';

class HomeInit {
    swipers: NodeListOf<Element>;
    tabs: NodeListOf<Element>;
    tabContents: NodeListOf<Element>;

    constructor() {
        this.getDOM();
        this.swiperInit();
        this.tabsInit();
        //new TabSlide();
    }

    getDOM() {
        this.swipers = document.querySelectorAll(".swiper");

        this.tabs = document.querySelectorAll(".tabs.picked li");
        this.tabContents = document.querySelectorAll(".tab-content.picked");
    }

    swiperInit() {      

        new Swiper(".home-hero-swiper", {
            autoplay: {
                delay: 8000,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: ".swiper-pagination",
            },
            //breakpoints: {
            //    700: {
            //        slidesPerView: "auto",
            //        spaceBetween: 16,
            //        navigation: {
            //            nextEl: '.swiper-button-next',
            //            prevEl: '.swiper-button-prev',
            //        },
            //    }
            //},
        });

        new Swiper(".tabs-swiper", { 
            slidesPerView: "auto",  
            spaceBetween: 10, 
            freeMode:true,           
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                500: {
                    slidesPerView: "auto",  
                    spaceBetween: 10,                     
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                },
                800: {
                    slidesPerView: "auto",  
                    spaceBetween: 10,                               
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                }
            },         
        });  
    }

    tabsInit() {
        this.tabs.forEach((tab, i) => {
            tab.addEventListener("click", () => {
                this.tabs.forEach(t => t.classList.remove("active"));
                this.tabContents.forEach(t => t.classList.remove("active"));
                this.tabs[i].classList.add("active");
                this.tabContents[i].classList.add("active");
            });
        });
    }
}




new HomeInit();