import "@styles/pages/store.scss";

import { createApp } from 'vue';
import EnquiryDestinations from '../Vue/Components/enquiry-destinations.vue'
import { validPhoneNumberForKuoni, isValidEmailAddressForKuoni } from './utils';

declare global {
    interface Window {
        grecaptcha: any
    }
}

const app = createApp({});
const appPartner = createApp({});
app.component("enquiry-destinations", EnquiryDestinations);
app.mount("[enquiry-destinations]");
appPartner.component("enquiry-destinations-partner", EnquiryDestinations);
appPartner.mount("[enquiry-destinations-partner]");

class EnquireConfig {
    selector: string; 
    trigger: string;
    thankyouMessage: string;
}

class EnquiryForm {
    
    sidebar: any;
    sidebarContent: HTMLElement;
    sidebarBody: HTMLElement;
    sidebarHeader: HTMLElement;
    storeIdInput: HTMLInputElement;
    storeNameInput: HTMLInputElement;
    fauxSelect: HTMLElement;
    fauxSelectSelected: HTMLElement;
    fauxDropdown: HTMLElement;
    fauxInput: any;
    fauxDropdownOptions: any;
    sidebarOpen: () => HTMLElement;
    sidebarClose: any;
    sidebarBookButton: HTMLButtonElement;
    sidebarForm: HTMLFormElement;
    sidebarNotSureOptions: NodeListOf<HTMLElement>;
    sidebarInputs: NodeListOf<HTMLInputElement>;
    sidebarDateInput: () => HTMLInputElement;
    sidebarTimeInput: () => HTMLInputElement;
    sidebarDateInputs: NodeListOf<HTMLInputElement>;
    body: HTMLBodyElement;
    theWindow: Window & typeof globalThis;
    scrollAmount: any;
    filterClear: any;
    filterTags: any;
    errors: number;
    form: HTMLFormElement;
    formInputs:any;
    nextStageButton: HTMLElement;
    config: EnquireConfig;
    currentOfferDestinationName: string;
    
    constructor(config: EnquireConfig) {
        this.config = config;
        this.getDOM(config.selector, config.trigger);
        this.initSidebar();
        this.initValidation();

        this.errors = 0;
    }

    getDOM(selector: string, trigger: string) {
        this.sidebar = document.querySelector(selector);
        if (this.sidebar) {
            this.sidebarContent = this.sidebar.querySelector(".sidebar__content");
            this.sidebarHeader = this.sidebar.querySelector(".sidebar__header");
            this.fauxInput = this.sidebar.querySelector("#faux-input");
            this.form = this.sidebar.querySelector("form");
        }
        if (this.sidebarContent) {
            this.sidebarBody = this.sidebarContent.querySelector(".sidebar__body");
            this.storeIdInput = this.sidebarContent.querySelector("[name='storeId']");
            this.storeNameInput = this.sidebarContent.querySelector("[name='storeName']");
            this.fauxSelect = this.sidebarContent.querySelector(".faux-select");
            this.sidebarClose = this.sidebarContent.querySelector(".close-sidebar");
            this.sidebarForm = this.sidebarContent.querySelector("form");
            this.sidebarNotSureOptions = this.sidebarContent.querySelectorAll(".not-sure");
            this.sidebarInputs = this.sidebarContent.querySelectorAll("input");
            this.sidebarDateInput = () => this.sidebarContent.querySelector("input#appointment-date");
            this.sidebarTimeInput = () => this.sidebarContent.querySelector("select#appointment-time");
            this.sidebarDateInputs = this.sidebarContent.querySelectorAll("input.date-capture");
        }
        if (this.fauxSelect) {
            this.fauxSelectSelected = this.fauxSelect.querySelector(".faux-select__selected");
            this.fauxDropdown = this.fauxSelect.querySelector(".faux-select__dropdown");
        }
        if (this.fauxDropdown) {
            this.fauxDropdownOptions = this.fauxDropdown.querySelectorAll("li");
        }
        
        this.sidebarOpen = () => document.querySelector(trigger);
        this.body = document.querySelector("body");
        this.theWindow = window;
        this.scrollAmount;

        if (this.form) {
            this.formInputs = this.form.querySelectorAll("input[required]:not([type='hidden']), select[required], textarea[required],input[data-validate-as-phone]");
            this.nextStageButton = this.form.querySelector("#form-button");
        }
    };

    unlockBody() {
        this.body.classList.remove("lock");
        (<any>this.body).style = "";
        this.theWindow.scrollTo(0, this.scrollAmount);
    }

    lockBody() {
        this.scrollAmount = this.theWindow.scrollY;
        this.body.classList.toggle("lock");
        this.body.style.top = -this.scrollAmount + "px";
    }

    initSidebar() {
        document.addEventListener("click", (e) => {
            let openTrigger = this.sidebarOpen();
            if (openTrigger != null && e.target instanceof Element) {
                if(e.target == openTrigger || e.target.parentNode == openTrigger) {
                    this.openSidebar(openTrigger.dataset.storeId, openTrigger.dataset.storeName, openTrigger.dataset.destination);
                }                
            }
        });
        
        if (this.sidebarNotSureOptions) {
            this.sidebarNotSureOptions.forEach(i => i.addEventListener("click", (e) => { 
                let targetElement = e.currentTarget as HTMLAnchorElement;
                let siblings = Array.from(targetElement.parentNode.parentNode.childNodes);
            
                var inputNearby = siblings.find(i => (i as HTMLElement).tagName == 'INPUT');
            
                if (inputNearby instanceof HTMLInputElement) {
                    if (inputNearby.id.indexOf('faux') > -1) { //#faux-input exists                   
                        this.createTag("I'm not sure yet", "enquiryDetails.destinations");
                    } else {
                        inputNearby.type = 'text'; //change input type to text
                        inputNearby.value = "I'm not sure yet";
                        inputNearby.dispatchEvent(new Event('keyup'));
                    }
                }
            }));
        }
        
        if (this.sidebarDateInputs) {
            this.sidebarDateInputs.forEach(i => i.addEventListener("click", (e) => { i.type = 'date';}));
        }

        if (this.sidebarClose) {
            this.sidebarClose.addEventListener("click", () => { this.closeSidebar() });
        }

        if (this.sidebar) {
            this.sidebar.addEventListener("click", () => { this.sidebar.classList.remove("active"); this.unlockBody(); });
        }

        if (this.sidebarContent) {
            this.sidebarContent.addEventListener("click", e => e.stopPropagation());
        }

        // if (this.fauxSelect) {
        //     this.fauxSelect.addEventListener("click", e => { e.stopPropagation(); this.fauxDropdown.classList.toggle("active"); });
        // }

        // if (this.fauxDropdownOptions) {
        //     this.fauxDropdownOptions.forEach(option => {
        //         option.addEventListener("click", (e) => this.createTag(option.textContent, e.target.dataset.name));
        //     });
        // }
    }

    openSidebarForCurrentOffer() {
        if(this.currentOfferDestinationName && document.querySelectorAll('[id="faux-id-'+ this.currentOfferDestinationName + '"]').length<2) //one element in the list, more means its selected
            this.setDestination(this.currentOfferDestinationName);

        this.sidebar.classList.add("active"); 
        this.lockBody();
    }
    openSidebar(storeId, storeName, destinationName) {
        if(storeId)
            this.setStore(storeId, storeName);

        if (destinationName && document.querySelectorAll('[id="faux-id-' + destinationName + '"]').length < 2) //one element in the list, more means its selected
        {
            this.setDestination(destinationName);
        }
        else {
            let ambientDestinationName = document.querySelector<HTMLInputElement>('[name="cta-sidebat-destination-name"]')?.value;
            if (!!ambientDestinationName) {
                this.setDestination(ambientDestinationName);
            }
        }

        let travelInfo = document.querySelector<HTMLTextAreaElement>('textarea#travel-info');
        let prefill = document.querySelector<HTMLInputElement>('#travel-information-prefill');
        if (!!prefill?.value && !travelInfo.value) {
            travelInfo.value = prefill.value;
        }

        this.sidebar.classList.add("active"); this.lockBody();
    }
    closeSidebar() {
        this.sidebar.classList.remove("active"); this.unlockBody();
    }

    setDestination(destinationName) {
        let matchingElements = document.querySelectorAll('[data-selector="' + destinationName + '"]');
        matchingElements.forEach(e => (<HTMLElement>e).click());
        this.sidebarContent.click(); // close list
    }

    setStore(id, storeName) {
        this.storeIdInput.value = id;
        this.storeNameInput.value = storeName;
        window.appointmentPiniaInstance.setLoading(true);
        window.appointmentPiniaInstance.selectStoreById(id);
        window.appointmentPiniaInstance.calculateDates().then(() => {
            window.appointmentPiniaInstance.selectDate(new Date(window.appointmentPiniaInstance.selectableDates[1] || window.appointmentPiniaInstance.selectableDates[0]));
            window.appointmentPiniaInstance.calculateTimes().then(() => {
                window.appointmentPiniaInstance.setLoading(false);
            })
        });
    }

    createTag(text, name) {
        if (this.sidebar.querySelector(`[id="faux-id-` + text +`"]`)) {
            // remove pill on UI
            this.sidebar.querySelector(`[id="faux-id-` + text +`"]`).remove();
            this.fauxSelectSelected.childElementCount == 0 && (this.fauxInput.value = "", this.fauxInput.nextElementSibling.style.display = "none");

            // remove input so value doesnt go to server
            var input = this.sidebar.querySelector(`[id="hidden-input-` + text +`"]`) as HTMLInputElement;   
            input.remove();
        } else {
            // add pill to UI
            let li = document.createElement("li");
            let img = document.createElement("img");
            img.src = "/images/icons/cancel.svg";
            li.textContent = text;
            li.id = "faux-id-" + text;           
            this.fauxInput.value = "selected";
            li.appendChild(img);
            li.addEventListener("click", e => e.stopPropagation());
            img.addEventListener("click", () => this.createTag(text, name));
            this.fauxSelectSelected.appendChild(li);
            this.fauxInput.nextElementSibling.style.display = "none";
            
            // add input to form for value post to server
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = name;
            input.value = text;
            input.id = 'hidden-input-' + text;
            this.sidebarForm.appendChild(input);
        }
    }
    
    sendData(form) {
        var formData = new FormData(this.form as HTMLFormElement) as any;

        console.log('Sending data', Array.from(formData));
        const XHR = new XMLHttpRequest();
        const urlEncodedDataPairs = [];

        // Turn the data object into an array of URL-encoded key/value pairs.
        for (const [name, value] of Array.from(formData) as any) {
            urlEncodedDataPairs.push(`${encodeURIComponent(name)}=${encodeURIComponent(value)}`);
        }

        // Combine the pairs into a single string and replace all %-encoded spaces to
        // the '+' character; matches the behavior of browser form submissions.
        const urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

        // Define what happens on successful data submission
        XHR.addEventListener('load', (event) => {
            let response = (<XMLHttpRequest>event.currentTarget);
            if (response.status != 200) {
                this.showFormError();
            } else {                
                var jsonResponse = JSON.parse(response.responseText);
                this.showFormThanks(jsonResponse);
            }
        });

        // Define what happens in case of error
        XHR.addEventListener('error', (event) => {
            this.showFormError();
        });

        // Set up our request
        XHR.open('POST', form.action);

        // Add the required HTTP header for form data POST requests
        XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

        // Finally, send our data.
        XHR.send(urlEncodedData);
    }

    showFormError() {
        this.sidebarForm.remove();
        this.sidebarHeader.remove();
        var div = document.createElement('div');
        div.className = "form-fail";
        var h6 = document.createElement('h6');
        var p = document.createElement('p');

        h6.innerText = "Sorry";
        p.innerText = "Something went wrong, please call with your enquiry.";

        div.appendChild(h6);
        div.appendChild(p);

        this.sidebarBody.appendChild(div);
        document.querySelectorAll('.sidebar__content').forEach(e => e.scrollTo({top: 0, behavior: 'smooth'}));
    }

    showFormThanks(responseObj) {            
        var div = document.createElement('div');
        div.className = "form-success";
        var imageDiv = document.createElement('div');
        if (responseObj.image) {
            imageDiv.setAttribute('style', "width:100%; height:300px; margin-bottom:25px;" + responseObj.image);
        } else {
            imageDiv.setAttribute('style', "width:100%; height:300px; margin-bottom:25px;" + window.appointmentPiniaInstance?.selectedStore?.imageCss);
        }        

        var h6 = document.createElement('h6');
        var p = document.createElement('p');

        var message = this.config.thankyouMessage;
        if(this.storeNameInput)
            message = message.replace('<STORE NAME>', this.storeNameInput.value);
        
        if(this.sidebarDateInput())
            message = message.replace('<DATE>', this.sidebarDateInput().value);
        
        if(this.sidebarTimeInput())
            message = message.replace('<TIME>', this.sidebarTimeInput().value);

        h6.innerText = "Thank you";
        p.innerText = message;

        div.appendChild(h6);
        div.appendChild(p);

        this.sidebarForm.remove();

        this.sidebarHeader.innerHTML = '';        
        this.sidebarHeader.appendChild(imageDiv);

        this.sidebarBody.appendChild(div);
        this.sidebarBody.style.padding = '0';
        document.querySelectorAll('.sidebar__content').forEach(e => e.scrollTo({top: 0, behavior: 'smooth'}));
    }

    initValidation() {
        if (this.form) {
            this.form.addEventListener("submit", e => e.preventDefault());
        }

        if (this.formInputs) {
            /*TODO: check if number is in number field, dob, legit day/month etc*/
            this.formInputs.forEach(input => {
                let smallTag = document.createElement("small");
                smallTag.className = "error";
                input.after(smallTag);

                if (input.type != "select-one") {
                    input.addEventListener("keyup", () => input.nextElementSibling.classList.contains("error") && (input.nextElementSibling.style.display = "none"));
                } else {
                    input.addEventListener("change", () => input.nextElementSibling.classList.contains("error") && (input.nextElementSibling.style.display = "none"));
                }
            });
        }

        if (this.nextStageButton) {
            this.nextStageButton.addEventListener("click", () => {
                this.errors = 0;
                this.formInputs.forEach(input => {
                    if (!input.value && input.required) {
                        this.errors += 1;
                        let label = input.previousElementSibling?.textContent;
                        let error = input.nextElementSibling;
                        if (error.classList.contains("error")) {
                            error.textContent = label != undefined ? `${label} is required` : "Required";
                            input.hidden && (error.textContent = "Destination required");
                            error.style.display = "block";
                        }
                    }
                    if (input.type == "email") {
                        let isValid = isValidEmailAddressForKuoni(input.value);
                        if (!isValid) {
                            this.errors += 1;
                            input.nextElementSibling.textContent = "Email is not valid";
                            input.nextElementSibling.style.display = "block";
                        }
                    }
                    if (input.pattern) {
                        let isValid = new RegExp(input.pattern).test(input.value);
                        if (!isValid) {
                            this.errors += 1;
                            input.nextElementSibling.textContent = "Please use a valid format";
                            input.nextElementSibling.style.display = "block";
                        }
                    }
                    if (input.dataset.validateAsPhone) {
                        if (!validPhoneNumberForKuoni(input.value)) {
                            if (input.required) {
                                this.errors += 1;
                                input.nextElementSibling.textContent = "Phone number must start with 0 or + and be 9 to 13 characters long.";
                                input.nextElementSibling.style.display = "block";
                            } else { //K-7-T15 - not required delete the number so its not routed to responseIQ
                                input.value = '';
                            }
                        }
                    }
                    //console.log(this.errors)                   
                });

                if(this.errors === 0){

                    this.nextStageButton.remove();
                    window.grecaptcha.ready(() => {
                        window.grecaptcha.execute((document.getElementById('reCaptchaSharedKey') as HTMLInputElement).value, {action: 'submit'}).then((token) => {
                            var input = document.createElement("input");
                            input.setAttribute("type", "hidden");
                            input.setAttribute("name", "ReCaptchaToken");
                            input.setAttribute("value", token);
                            this.sidebarForm.appendChild(input);                          
                            this.sendData(this.sidebarForm);
                        });
                    });

                }else{
                    let errorInput = document.querySelector(".sidebar__body .form-input small.error[style='display: block;']").parentNode as HTMLElement; //get 1st error
                    errorInput.scrollIntoView({behavior: "smooth"})
                }             
            });
        }
    }
};

window.kuoni = window.kuoni || {};
window.kuoni.enquire = new EnquiryForm({selector:'.sidebar.enquire', trigger:'#open-sidebar', thankyouMessage: window['CmsGlobalContent'].KuoniEnquireThankYouText || "Your enquiry has been recieved and we will respond as soon as possible."});
window.kuoni.partnerForm = new EnquiryForm({selector:'.sidebar.partner', trigger:'#open-partner-sidebar', thankyouMessage: window['CmsGlobalContent'].PartnerEnquireThankYouText || 'Your request for an appointment with <STORE NAME> on <DATE> at <TIME> has been sent'});